import request from '@/utils/request'


// 查询单元房间列表
export function listRoom(query) {
  return request({
    url: '/user/village-rooms/list',
    method: 'get',
    params: query
  })
}

// 查询单元房间分页
export function pageRoom(query) {
  return request({
    url: '/user/village-rooms/page',
    method: 'get',
    params: query
  })
}

// 查询单元房间详细
export function getRoom(data) {
  return request({
    url: '/user/village-rooms/detail',
    method: 'get',
    params: data
  })
}

// 新增单元房间
export function addRoom(data) {
  return request({
    url: '/user/village-rooms/add',
    method: 'post',
    data: data
  })
}

// 修改单元房间
export function updateRoom(data) {
  return request({
    url: '/user/village-rooms/edit',
    method: 'post',
    data: data
  })
}

// 删除单元房间
export function delRoom(data) {
  return request({
    url: '/user/village-rooms/delete',
    method: 'post',
    data: data
  })
}
