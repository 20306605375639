import { render, staticRenderFns } from "./VillageUnitList.vue?vue&type=template&id=55f5bfc5&scoped=true"
import script from "./VillageUnitList.vue?vue&type=script&lang=js"
export * from "./VillageUnitList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f5bfc5",
  null
  
)

export default component.exports