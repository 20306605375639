import request from '@/utils/request'


// 查询楼栋单元列表
export function listUnit(query) {
  return request({
    url: '/user/village-units/list',
    method: 'get',
    params: query
  })
}

// 查询楼栋单元分页
export function pageUnit(query) {
  return request({
    url: '/user/village-units/page',
    method: 'get',
    params: query
  })
}

// 查询楼栋单元详细
export function getUnit(data) {
  return request({
    url: '/user/village-units/detail',
    method: 'get',
    params: data
  })
}

// 新增楼栋单元
export function addUnit(data) {
  return request({
    url: '/user/village-units/add',
    method: 'post',
    data: data
  })
}

// 修改楼栋单元
export function updateUnit(data) {
  return request({
    url: '/user/village-units/edit',
    method: 'post',
    data: data
  })
}

// 删除楼栋单元
export function delUnit(data) {
  return request({
    url: '/user/village-units/delete',
    method: 'post',
    data: data
  })
}
